import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { filter, first, map, Observable, shareReplay } from 'rxjs';

import { CUSTOM_APP_CONFIG } from '../factories/custom-config-initializer-factory';

interface GoogleRecaptcha {
  execute: (recaptchaSiteKey: string, parameters: { action: string }) => Promise<string>;
  ready: (callback: () => void) => void;
  render: (container: string, parameters: { sitekey: string }) => void;
}

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private readonly appConfig = inject(CUSTOM_APP_CONFIG);
  private readonly document = inject(DOCUMENT);

  private googleRecaptcha?: GoogleRecaptcha;
  private recaptchaSiteKey$ = this.appConfig.pipe(
    filter(Boolean),
    first(),
    map(({ reCaptchaSiteKey }) => reCaptchaSiteKey),
    shareReplay(),
  );

  constructor() {
    this.load();
  }

  execute(action: string): Observable<string> {
    if (!this.googleRecaptcha) throw 'Recaptcha has not been loaded yet, you have to invoke the load() method firstly';

    return new Observable((subscriber) => {
      try {
        this.recaptchaSiteKey$.subscribe((recaptchaSiteKey) =>
          this.googleRecaptcha?.ready(() =>
            this.googleRecaptcha?.execute(recaptchaSiteKey, { action }).then((recaptchaToken) => {
              subscriber.next(recaptchaToken);
              subscriber.complete();
            }),
          ),
        );
      } catch (error) {
        subscriber.error(error);
      }
    });
  }

  private load(): void {
    this.recaptchaSiteKey$.subscribe((recaptchaSiteKey) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const defaultView = this.document.defaultView as Record<string, any>;

      if (defaultView) {
        defaultView['recaptchaLoaded'] = () => {
          this.googleRecaptcha = defaultView['grecaptcha'] as GoogleRecaptcha;
        };
      }

      const script = this.document.createElement('script');

      script.async = true;
      script.defer = true;
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}&onload=recaptchaLoaded`;

      this.document.head.appendChild(script);
    });
  }
}
