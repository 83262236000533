import { HttpBackend, HttpClient, HttpRequest } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';

import { BehaviorSubject, tap } from 'rxjs';

import { SsoCustomConfig } from '../models/custom-config';

export const CUSTOM_APP_CONFIG = new InjectionToken<BehaviorSubject<SsoCustomConfig | null>>('Custom App Config', {
  factory: () => new BehaviorSubject<SsoCustomConfig | null>(null),
  providedIn: 'root',
});

export const customConfigInitializerFactory = () => {
  const customAppConfigSource = inject(CUSTOM_APP_CONFIG);
  const httpBackend = inject(HttpBackend);

  const httpClient = new HttpClient({ handle: (req: HttpRequest<void>) => httpBackend.handle(req) });

  return () =>
    httpClient
      .get<SsoCustomConfig>('assets/custom-app-config.json')
      .pipe(tap(({ reCaptchaSiteKey }) => customAppConfigSource.next({ reCaptchaSiteKey })));
};
