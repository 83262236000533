import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SignInResponseModel } from '../../../models/account/sign-in.models';
import { SsoSingleSignOnResponseModel } from '../../../models/account/sso-sign-in.models';
import { SSOHttpErrorResponse } from '../../../models/errors.model';
import { TfaCodeProvider } from '../../../models/profile/tfa-code-provider.enum';

export const loginApiActions = createActionGroup({
  source: 'Login Api',
  events: {
    'Login Successfully': props<{ signInResponseModel: SignInResponseModel }>(),
    'Login Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
    'Login via Two Factor Successfully': emptyProps(),
    'Login via Two Factor Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
    'Login Via Single Sign On Successfully': props<{ ssoSingleSignOnResponseModel: SsoSingleSignOnResponseModel }>(),
    'Login Via Single Sign On Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
    'Sending Code Again Successfully': props<{ method: TfaCodeProvider }>(),
    'Sending Code Again Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
    'Sending Code Again After Two Factor Changed Successfully': emptyProps(),
    'Sending Code Again After Two Factor Changed  Failed': props<{ error: SSOHttpErrorResponse['error'] }>(),
  },
});
