import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, filter, map, mergeMap, of, switchMap, throwError, tap, withLatestFrom, EMPTY } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AccountService } from '../../account.service';
import { DocumentService } from '../../core/document.service';
import { loginApiActions } from './actions/login-api.actions';
import { loginFeatureActions } from './actions/login-feature.actions';
import { LoginStep } from './login.reducer';
import { RecaptchaService } from '../../core/recaptcha/recaptcha.service';
import { selectActiveTwoFactorMethod, selectPasswordForce, selectRememberMe, selectReturnUrl } from './login.selectors';
import { SSOHttpErrorResponse } from '../../models/errors.model';
import { TfaCodeProvider } from '../../models/profile/tfa-code-provider.enum';
import { TfaSignInProvider } from '../../models/account/tfa-sign-in-provider.enum';
import { uiActions } from '../../state/ui.actions';
import { userNameLocalStorageKey } from './user-name-local-storage-key.const';

@Injectable({ providedIn: 'root' })
export class LoginEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private accountService = inject(AccountService);
  private documentService = inject(DocumentService);
  private recaptchaService = inject(RecaptchaService);

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.login),
      mergeMap(({ signInRequestModel }) => {
        return this.recaptchaService.execute('submit').pipe(
          switchMap((recaptchaToken) => {
            return this.accountService.signIn({ ...signInRequestModel, recaptchaToken }).pipe(
              map((signInResponseModel) => loginApiActions.loginSuccessfully({ signInResponseModel })),
              catchError((httpErrorResponse: SSOHttpErrorResponse) => {
                this.store.dispatch(loginApiActions.loginFailed({ error: httpErrorResponse.error || null }));
                return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
              }),
            );
          }),
          catchError((error) => (error instanceof HttpErrorResponse ? throwError(() => error) : of(loginFeatureActions.reCaptchaFailed()))),
        );
      }),
    ),
  );

  loginSuccessfully$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.loginSuccessfully),
      map(({ signInResponseModel }) => {
        const { isTwoFactorRequired, hasPasswordExpired, passwordMatchesPolicy, isTwoFactorConfigurationRequired, returnUrl } =
          signInResponseModel;

        let loginStep: LoginStep = 'login';

        if (!isTwoFactorRequired && !hasPasswordExpired && !isTwoFactorConfigurationRequired && passwordMatchesPolicy) {
          returnUrl ? this.documentService.redirectLocationHref(returnUrl) : this.documentService.reloadDocument();
          return loginFeatureActions.setLoginStep({ step: 'login' });
        } else {
          const passwordForce = hasPasswordExpired || !passwordMatchesPolicy;

          if (isTwoFactorConfigurationRequired) {
            loginStep = 'twoFactorAuthenticationForce';
          }

          if (isTwoFactorConfigurationRequired && passwordForce) {
            loginStep = 'passwordChange';
          }

          if (isTwoFactorRequired) {
            loginStep = 'twoFactorAuthentication';
          }

          if (isTwoFactorRequired && passwordForce) {
            loginStep = 'twoFactorAuthentication';
          }
          if (!isTwoFactorRequired && passwordForce) {
            loginStep = 'passwordChange';
          }
          return loginFeatureActions.setLoginStep({ step: loginStep });
        }
      }),
    ),
  );

  loginViaTwoFactorCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.loginViaTwoFactor),
      withLatestFrom(this.store.select(selectActiveTwoFactorMethod).pipe(filter(Boolean))),
      mergeMap(([{ verifyTwoFactorCodeRequestModel }, activeMethod]) => {
        return this.accountService
          .verifyToFactorCode({
            ...verifyTwoFactorCodeRequestModel,
            method: activeMethod,
          })
          .pipe(
            map(() => loginApiActions.loginViaTwoFactorSuccessfully()),
            catchError((httpErrorResponse: SSOHttpErrorResponse) => {
              this.store.dispatch(loginApiActions.loginFailed({ error: httpErrorResponse.error || null }));
              return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
            }),
          );
      }),
    ),
  );

  loginViaTwoFactorSuccessfully$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.loginViaTwoFactorSuccessfully),
      withLatestFrom(this.store.select(selectPasswordForce)),
      map(([, passwordForce]) =>
        passwordForce ? loginFeatureActions.setLoginStep({ step: 'passwordChange' }) : loginFeatureActions.redirectToDesireApplication(),
      ),
    ),
  );

  loginViaSingleSignOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.loginViaSingleSignOn),
      mergeMap(({ userName, returnUrl }) => {
        return this.recaptchaService.execute('submit').pipe(
          switchMap((recaptchaToken) => {
            return this.accountService.signInViaSingleSignOn({ userName, returnUrl, recaptchaToken }).pipe(
              map((ssoSingleSignOnResponseModel) => loginApiActions.loginViaSingleSignOnSuccessfully({ ssoSingleSignOnResponseModel })),
              catchError((httpErrorResponse: SSOHttpErrorResponse) => {
                this.store.dispatch(loginApiActions.loginViaSingleSignOnFailed({ error: httpErrorResponse.error || null }));
                return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
              }),
            );
          }),
          catchError((error) => (error instanceof HttpErrorResponse ? throwError(() => error) : of(loginFeatureActions.reCaptchaFailed()))),
        );
      }),
    ),
  );

  loginViaSingleSignOnSuccessfully$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginApiActions.loginViaSingleSignOnSuccessfully),
        tap(({ ssoSingleSignOnResponseModel: { redirectUrl } }) => {
          this.documentService.redirectLocationHref(redirectUrl);
        }),
      ),
    { dispatch: false },
  );

  sendCodeAgain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.sendCodeAgain),
      mergeMap(({ method }) =>
        this.accountService.sendTwoFactorCode({ method }).pipe(
          map(() => loginApiActions.sendingCodeAgainSuccessfully({ method })),
          catchError((httpErrorResponse: SSOHttpErrorResponse) => {
            this.store.dispatch(loginApiActions.sendingCodeAgainFailed({ error: httpErrorResponse.error || null }));
            return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
          }),
        ),
      ),
    ),
  );

  changeTwoFactorAuthenticationMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.changeTwoFactorAuthenticationMethod),
      filter(({ method }) => method === TfaSignInProvider.Sms || method === TfaSignInProvider.Email),
      map(({ method }) => loginFeatureActions.sendCodeAfterChangedTwoFactorMethod({ method: method as unknown as TfaCodeProvider })),
    ),
  );

  sendCodeAgainAfterChangedTwoFactorMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginFeatureActions.sendCodeAfterChangedTwoFactorMethod),
      mergeMap(({ method }) =>
        this.accountService.sendTwoFactorCode({ method }).pipe(
          map(() => loginApiActions.sendingCodeAgainAfterTwoFactorChangedSuccessfully()),
          catchError((httpErrorResponse: SSOHttpErrorResponse) => {
            this.store.dispatch(loginApiActions.sendingCodeAgainAfterTwoFactorChangedFailed({ error: httpErrorResponse.error || null }));
            return httpErrorResponse.error ? EMPTY : throwError(() => httpErrorResponse);
          }),
        ),
      ),
    ),
  );

  sendingCodeAgainSuccessfully = createEffect(() =>
    this.actions$.pipe(
      ofType(loginApiActions.sendingCodeAgainSuccessfully),
      map(({ method }) =>
        uiActions.displaySuccessToast({
          toastMessage:
            method === TfaCodeProvider.Email
              ? 'MULTI_FACTOR_AUTHENTICATION.MESSAGES.SEND_AGAIN_EMAIL'
              : 'MULTI_FACTOR_AUTHENTICATION.MESSAGES.SEND_AGAIN_TEXT_MESSAGE',
        }),
      ),
    ),
  );

  redirectToDesireApplication = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFeatureActions.redirectToDesireApplication),
        withLatestFrom(this.store.select(selectReturnUrl)),
        tap(([, returnUrl]) => (returnUrl ? this.documentService.redirectLocationHref(returnUrl) : this.documentService.reloadDocument())),
      ),
    {
      dispatch: false,
    },
  );

  removeUserName$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFeatureActions.setRememberMe),
        filter(({ rememberMe }) => !rememberMe),
        tap(() => localStorage.removeItem(userNameLocalStorageKey)),
      ),
    { dispatch: false },
  );

  storeUserName$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFeatureActions.login, loginFeatureActions.loginViaSingleSignOn),
        map((action) => (action.type === loginFeatureActions.login.type ? action.signInRequestModel.userName : action.userName)),
        withLatestFrom(this.store.select(selectRememberMe)),
        tap(([userName, rememberMe]) => rememberMe && localStorage.setItem(userNameLocalStorageKey, userName)),
      ),
    { dispatch: false },
  );
}
